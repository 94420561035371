/* Resume Template Styles */

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.lora-regular {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.noto-serif { 
    font-family: "Noto Serif", serif;
    font-optical-sizing: auto; 
    font-style: normal;
    font-variation-settings: "wdth" 100;
  }
.overpass-regular {
  font-family: "Overpass", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.quicksand-regular {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.raleway-regular {
  font-family: "Raleway", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.lato-regular {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.rubik-regular {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}
.inconsolata-regular{
  font-family: "Inconsolata", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:"wdth" 100;
}
.signika-regular {
  font-family: "Signika Negative", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.ubuntu-regular {
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.kanit-regular {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.playwrite-de-grund{
  font-family: "Playwrite DE Grund", cursive;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.roboto-mono {
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}
.prompt-regular {
  font-family: "Prompt", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.abeezee-regular {
  font-family: "ABeeZee", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.lexend {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.pt-serif-regular {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-style: normal;
}

*{
  margin: 0;
  padding: 0;
}
body {
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  color:#000;
}

body:after {
  content: '';
  position: absolute;
  width: 282px;
  top: 0;
  bottom: 0;
  z-index: -1;
}
img, video {
  max-width: inherit;
  height: auto;
}
/* Resume Container & Layout */
.resume-container {
  display: flex;
  flex-wrap: nowrap;
  margin: 0 auto;
  position: relative;
  width: 800px;
  min-width: 800px;  
  overflow: visible;
  box-shadow: 2px 2px 6px #33333330;
  background: #fff;
  font-size: 14px;
  /* min-height: 980px; */
  min-height: 11in;
}

.section-content {
  min-height: 60px;
  border-radius: 10px;
}
.header-section .section-content{ 
  min-height: 100px;
  width: 100%;
}
/* Print Styles */
@media print {
    .section-content { 
      min-height: auto;
      border-radius: 0;
    }
    .header-section { 
      min-height: auto; 
    }
    .resume-container {
      display: flex;
      flex-wrap: nowrap;
      margin: 0 auto;
      position: relative;
      width: 100%; 
      max-width: initial;
      overflow: visible;
      box-shadow: none;
      min-height: initial;
    }
}

.left-column {
  flex: 30%;
  color: #fff;
  position: relative;
  z-index: 2;
}

.right-column {
  flex: 70%;
}
/*Resume Structure Styles*/
  .profile {
      text-align: center;
      margin-bottom: 20px;
  }
  .profile img {
      width: 100px; /* Adjust as needed */
      height: 100px; /* Adjust as needed */
      border-radius: 50%;
  }
  .header h1 {
    margin: 0;
    color: #333333;
  }
  .header h2 {
    margin: 5px 0 0;
    color: #555555;
  }
  .section {
    margin-bottom: 30px;
  }
  .sub-section {
    margin-bottom: 10px;
  }
  .section-title { 
    margin-bottom: 10px; 
    font-size: 20px;
    font-weight: 700;
  }
  .left-column .section-title {
    /* border-bottom: 2px solid #ffffff; */
  }
  .contact-info,
  .personal-details,
  .references,
  .social-links {
    font-size: 0.9em; 
  }
  .social-links a {
    color: #0000ee;
    text-decoration: none;
    display: block; /* Makes each link appear on a new line */
  }
  .profile-pic {
    width: 100%; /* Adjust width as needed */
    padding: 10px 0; /* Adjust padding as needed */
  }
  .profile-pic img {
    width: 100%; /* Adjust width as needed */
    border-radius: 50%;
  }
        .page-break {
            page-break-after: always;
        } 

 

.personal-details-section{
  display: flex;
  margin-bottom: 30px;
  flex-direction: column;
}
.personal-details-section h1 {
  font-size: 25px;
  font-weight: 700;
  /* line-height: 27px; */
}
.personal-details-section h2 {
  font-size: 18px;
  font-weight: 500;
}
.personal-details-section .personal-photo{
  margin-bottom: 15px;
}
.personal-details-section .personal-photo img {
  width: 120px;
  border-radius: 10px;
  margin: 0 auto;
}
.textarea { 
  margin-top: 5px;
  /* padding-bottom: 10px; */
}
/* .textarea p {
  margin-bottom: 15px;
} */
ul, li{
  margin: 0;
  padding: 0;
}
.section ul {
  list-style: disc; 
  width: calc(100% - 25px);
  padding-left: 25px;
  margin-left: 0;
}
.section ul.no-bullets{
  list-style: none;
  padding-left: 0 !important;
}
.section ul li { 
  padding-left: 0; 
  margin-left: 0;
}
.course-name,
.award-name,
.reference-name,
.custom-section .title,
.position,
.project-title,
.certificate,
.role{
  font-weight: 600;
}
p.date-range, .issue-date,.end-date {
  font-size: 13px;
}
p.issued-date{
  font-size: 13px;
}

.skill-level-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.skill-level-bar {
  height: 10px;
  border-radius: 4px;
  background-color: #4caf50;
}
.contact-detail .contact-value {
  display: flex;     
  align-items: baseline; 
}
span.contact-icon { 
  padding: 5px 0 0 0;
}
.left-column span.contact-icon {
  padding: 5px 0 0 0;
  width: 22px;
}
span.contact-text { 
  word-break: break-word;

}
.header-section .section-content.contact-details-content {
  display: flex;
  flex-wrap: wrap;
}
.header-section .section {
  margin: 0;
}
.progress-bar-container {
  width: 100%;
  background-color: rgb(224 224 224 / 32%);
  border-radius: 0px;
}

.progress-bar {
  background-color: #fff;
  height: 7px;
  border-radius: 0px;
}
.skill-set.circle,.skill-set.square,
.languages-set.circle, .languages-set.square
{
  display: flex;
  justify-content: space-between;
}
.skill-set.circle .skill-name,.skill-set.square .skill-name, 
.languages-set.circle .languages-name, .languages-set.square .languages-name

{
  width: 55%;
}
p.languages-name, p.skill-name{
  margin-bottom: 5px;
}
.dash-line .skill-level-visualization,
.dash-line.languages-set div, .dash-line .languages-level-visualization{
  display: flex;
  justify-content: space-between;
}

.dash-line .skill-level-visualization,
.dash-line .languages-level-visualization {
  transform: skew(-0.06turn, 0deg);
}
.dash-line .skill-level-visualization,
.dash-line .languages-level-visualization,
.progress-bar-container{
  margin-bottom: 5px;
}
.personal-info {
  text-align: center;
}



span.contact-text {
  word-break: break-word;
  margin-right: 15px;
}

.columns {
  display: flex;
  width: 100%;
}

.left-column, .right-column {
  padding: 35px;
}

.left-column {
  flex: 0.9; /* You can adjust this value as needed */
}
.right-column {
  flex: 2.1; /* You can adjust this value as needed */
}

/**Header common*/
.header-section{
  display: flex;
}
.header-section .personal-photo {
  display: flex;
  padding: 15px;
  padding-right: 0px;
  height: 100%;
  align-items: center;
}
.header-section img.personal-photo {
  width: 100%;
  height: auto;
}
.header-section .personal-info {
  text-align: left;
}
.header-section .section.contact-details-section h2 {
  display: none;
}
.header-section .contact-detail {
  float: left;
}
.resume-container .left-column .personal-photo {
  margin: 0 auto;
  width: 125px;
  display: flex;
  margin-bottom: 25px;
  border-radius: 50%;
}
.sub-header {
  display: flex;
  justify-content: space-between;
}
p.date-range, .issue-date, .end-date {
  font-size: 12px;
  font-weight: 500;
}
/*Theme: Professional*/
.resume-container.professional {
  display: block !important; 
}
.resume-container.professional:after{
  display: none;
}
.resume-container.professional .header-section {
  display: flex;
  background: #293993;
  z-index: 5;
  position: relative;
  color: #fff;
  padding: 0 40px !important;
}
.resume-container.professional .personal-details-section h1 {
  font-size: 32px;
  line-height: normal;
  font-weight: 600;
}
.resume-container.empowering .details-section {
  padding: 20px 0 !important;
}
.resume-container.empowering .header-section {
  padding: 0 35px !important;
}
.resume-container.professional .left-column { 
  color: #202020; 
}
.resume-container.professional .details-section {
  padding: 30px;
}
.resume-container.professional .columns {
  display: flex;
  flex-direction: row-reverse;
}
.resume-container.professional .left-column {
  color: #202020;
  border-left: 1px solid #cccccc;
}
.resume-container.professional .personal-details-section {
  margin-bottom: 10px;
}

.resume-container.professional .header-section img.personal-photo { 
  height: auto;
}
.resume-container.professional .header-section .personal-info {
  text-align: left;
}
.resume-container.professional .section.contact-details-section h2 {
  display: none;
}
.resume-container.professional .contact-detail {
  float: left;
}
.resume-container.professional .ratingForeColor{
  background: #293993 !important;
} 
.resume-container.professional .ratingBackColor{
  background: #d3d3d3 !important;
}
/*End professional*/

/*Theme: bold*/
.resume-container.bold {
  display: block; 
}
.resume-container.bold:after{
  background: #26262d; 
}
.resume-container.bold .header-section {
  display: flex;
  background: #4b93eb;
  z-index: 5;
  position: relative;
  color: #fff;
  border: 16px solid #fff;
  top: 20px;
  border-right: 0;
  margin-bottom: 20px;
  margin-left: 30px;
  padding: 0 32px !important;
}
.resume-container.bold .left-column { 
  color: #fff; 
}
.resume-container.bold .details-section {
  padding: 30px;
}
.resume-container.bold .columns {
  display: flex;
  width: 100%; 
}
.resume-container.bold .sub-header {
  display: flex;
  justify-content: space-between;
}
.resume-container.bold .sub-header .date-range {
  font-style: italic;
}
.resume-container.bold .left-column {
  color: #fff;
}
.resume-container.bold .personal-details-section {
  margin-bottom: 10px;
}
.resume-container.bold .header-section img.personal-photo {
  width: 100%;
  height: auto;
}
.resume-container.bold .header-section .personal-info {
  text-align: left;
}
.resume-container.bold .section.contact-details-section h2 {
  display: none;
}
.resume-container.bold .contact-detail {
  float: left;
}
.resume-container.bold .ratingForeColor{
  background: #4b93eb !important;
} 
.resume-container.bold .ratingBackColor{
  background: #d3d3d3 !important;
}

/* Modern style */
.resume-container.modern .ratingForeColor{
  background: #fff !important;
} 
.resume-container.modern .ratingBackColor{
  background: #ffffff6b !important;
}

/* Impactful style */
.resume-container.impactful .ratingForeColor{
  background: #fff !important;
} 
.resume-container.impactful .ratingBackColor{
  background: #ffffff6b !important;
}
.resume-container.impactful .columns{
  flex-direction: row-reverse;
}
.resume-container.impactful .personal-details-section h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.resume-container.impactful .section-title {
  padding-bottom: 5px;
}
.resume-container.impactful .right-column .section-title {
  border-bottom: 1px dashed #096e65;
}
.resume-container.impactful .left-column .section-title {
  border-bottom: 1px dashed #fff;
}
.resume-container.impactful .right-column .contact-details-section {
  display: none;
}
.resume-container.impactful .header-section .personal-photo {
  display: flex;
  padding: 0;
  padding-right: 0px;
  height: initial;
  align-items: center;
  width: 100px;
  margin-right: 15px;
}

.resume-container.modern .header-section {
  background: #e5e5e5;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  margin-bottom: 40px;
}
.resume-container.modern .right-column h2.section-title {
  border-bottom: 3px solid #e5e5e5;
  padding-bottom: 5px;
}
.resume-container.modern .header-section .personal-details-section {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.resume-container.modern .section.professional-summary-section .section-title {
  display: none;
}
.resume-container.modern .header-section .personal-info {
  text-align: center;
}
.resume-container.modern .personal-details-section h1 {
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
}
.resume-container.modern .personal-details-section h2 {
  font-size: 16px;
  font-weight: 500;
}
.resume-container.impactful .header-section img.personal-photo { 
  border-radius: 50%;
}
.resume-container.impactful .header-section .personal-details-section {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.resume-container.impactful .header-section {
  margin-bottom: 30px;
  padding: 0 !important;
}
/*Elegant style*/
.resume-container.elegant .ratingForeColor{
  background: rgb(18, 17, 17) !important;
} 
.resume-container.elegant .ratingBackColor{
  background: #b0b0b0 !important;
}
.resume-container.elegant .columns{
  flex-direction: row-reverse;
}
.resume-container.elegant:after { 
  width: 264px; 
  background:#fff;
  right: 0;
}
.resume-container.elegant .columns .left-column {
  color: rgb(18, 17, 17);
  border-left: solid #d8d8d8 1px;
}
.resume-container.elegant .personal-details-section h1 {
  font-size: 40px;
  line-height: normal;
}
.resume-container.elegant .right-column .contact-details-section {
  display: none;
}
.resume-container.elegant .header-section .personal-photo {
  display: flex;
  padding: 0;
  padding-right: 0px;
  height: 100%;
  align-items: center;
  width: 100px;
  margin-right: 15px;
}
.resume-container.elegant .header-section {
  margin-bottom: 30px;
  border-bottom: 1px solid #d8d8d8; 
  padding: 0 !important;
}
.resume-container.elegant .header-section img.personal-photo { 
  border-radius: 50%;
}
.resume-container.elegant .header-section .personal-details-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
  border: none !important;
}
.resume-container.elegant .columns .section {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 15px;
  margin-bottom: 20px;
}
.resume-container.elegant {
  padding: 0 10px;
}
/*Theme: Professional*/
.resume-container.creative {
  display: block; 
}
.resume-container.creative:after{
  display: none;
}
.resume-container.creative .header-section {
  display: flex;
  border-bottom: 1px solid #ccc;
  background: white;
  z-index: 5;
  position: relative;
  color: #373737;
}
.resume-container.creative .header-section .personal-details-section h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: normal;
}
.resume-container.creative .left-column { 
  color: #202020; 
}
.resume-container.creative .details-section {
  padding: 30px;
}
.resume-container.creative .columns {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding: 0;
}
.resume-container.creative .left-column {
  color: #202020;
  border-left: 1px solid #cccccc;
}
.resume-container.creative .personal-details-section {
  margin-bottom: 10px;
}
.resume-container.creative .header-section .personal-photo {
  display: flex;
  padding: 0;
  margin: 0;
  height: 100%;
  align-items: center;
  width: 110px;
}
.resume-container.creative .header-section img.personal-photo {
  height: auto;
}
.resume-container.creative .header-section .personal-info {
  text-align: left;
}
.resume-container.creative .section.contact-details-section h2 {
  display: none;
}
.resume-container.creative .contact-detail {
  float: left;
}

.resume-container.creative .ratingForeColor{
  background: #202020 !important;
} 
.resume-container.creative .ratingBackColor{
  background: #d3d3d3 !important;
}
/* Influential */

/*Elegant style*/
.resume-container.influential {
  padding: 0;
}
.resume-container.influential .ratingForeColor{
  background: #fff !important;
} 
.resume-container.influential .ratingBackColor{
  background: #b0b0b0 !important;
}
.resume-container.influential .columns{
  flex-direction: row-reverse;
}
.resume-container.influential .columns .left-column {
  color: #fff;
}

.resume-container.influential .header-section .personal-photo {
  display: flex;
  padding: 0;
  padding-right: 0px;
  height: 100%;
  align-items: center;
  width: 100px;
  margin-right: 15px;
}
.resume-container.influential .header-section {
  margin-bottom: 30px;
  border-bottom: 4px solid #283c50;
  padding-bottom: 20px;
}
.resume-container.influential .header-section img.personal-photo { 
  border-radius: 50%;
}
.resume-container.influential .header-section .personal-details-section {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.resume-container.influential .personal-details-section h1 {
  font-size: 45px;
  font-weight: 700;
  line-height: normal;
}

/*Theme: visionary*/
.resume-container.visionary {
  display: block; 
  padding: 0;
}
.resume-container.visionary:after{
  background: none;
}
.resume-container.visionary .header-section {
  display: flex;
  background: #3b4956;
  z-index: 5;
  position: relative;
  color: #d3c6b5;
  align-items: center;
}
.resume-container.visionary .left-column { 
  color: #202020; 
}
.resume-container.visionary .details-section {
  padding: 40px 0px !important;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.resume-container.visionary .columns {
  display: flex; 
  padding:0 20px;
}
.resume-container.visionary .left-column {
  color: #202020;
  border-right: 1px solid #cccccc;
}
.resume-container.visionary .columns .left-column {padding-right:20px;}
.resume-container.visionary .columns .right-column {padding-left:20px;}
.resume-container.visionary .personal-details-section {
  margin-bottom: 0px;
}
/* .resume-container.visionary .right-column .section, .resume-container.visionary .left-column .section {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 15px;
} */
.resume-container.visionary .section-title {
  margin-bottom: 12px;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  border-bottom: 2px solid #242d3e;
  padding:0 0 10px;
    border-left: none;
    border-right: none;
}
.resume-container.visionary .header-section .personal-photo {
  display: flex;
  margin: 0 !important;
  padding-right: 0px;
  height: 100%;
  align-items: center;
  width: 120px !important;
}
.resume-container.visionary .header-section.photo-exists .personal-info {
  text-align: left;
}
.resume-container.visionary .personal-details-section h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 5px;
  letter-spacing: 1px;
}
.resume-container.visionary .personal-details-section h2 {
  FONT-VARIANT: JIS04;
  font-weight: 600;
  text-transform: uppercase; 
  margin: 0;
  padding: 0;
  letter-spacing: 2px;
  color: #d9ae47 !important;
}
.resume-container.visionary .sub-section-col h3{
  color: #b68510 !important;
}
.resume-container.visionary .header-section img.personal-photo {
  width: 100%;
  height: auto;
}
.resume-container.visionary .header-section .personal-info {
  text-align: center;
}
.resume-container.visionary .header-section span.contact-icon {
  padding: 5px 0 0 0;
  width: auto;
  margin-right: 5px;
}
.resume-container.visionary .ratingForeColor{
  background: #b68510 !important;
} 
.resume-container.visionary .ratingBackColor{
  background: #d3d3d3 !important;
}
/*End visionary*/

/*Executive*/
.resume-container.executive .single-column .wrap{
  padding: 0;
}
.resume-container.executive .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.executive .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.executive .header-section .personal-photo {
  padding: 0; 
  margin-right: 0;
}
.resume-container.executive img.personal-photo {
  width: 110px;
  height: auto; 
}
.resume-container.executive .header-section {
  padding: 0px !important;
  margin-bottom: 30px;
  border-bottom: 4px solid #333;
}
.resume-container.executive .header-section .contact-detail .contact-value {
  display: flex;
  color: black;
  font-size: 13px;
}
.resume-container.executive .header-section .contact-details-section .section-title {
  display: none !important;
}
.resume-container.executive .single-column .section-title {
  line-height: normal;
  border-bottom: 2px solid rgb(59, 59, 59);
  display: inline-block;
}
.resume-container.executive .header-section .section.personal-details-section {
  margin-bottom: 7px;
}

.resume-container.executive .header-section .personal-details-section h1 {
  font-size: 34px;
  line-height: normal;
}

.resume-container.executive .languages-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}
.resume-container.executive .skills-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}

.resume-container.vibrant .section-title {
  margin-bottom: 17px;
  font-size: 20px;
  font-weight: 700;
  color: #07297e;
  border-bottom: 2px solid #07297e;
  padding-bottom: 5px;;
}
.resume-container.vibrant .personal-details-section h1 {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
.resume-container.vibrant .left-column .section-title {
  border-bottom: 2px solid #ffffff6b;
  color: white;
}
.resume-container.vibrant img.personal-photo {
  border: 2px solid #fff;
}

/*Minimalist*/
.resume-container.minimalist .single-column .section {
  display: flex;
}
.resume-container.minimalist .single-column .wrap {
  padding: 0;
}
.resume-container.minimalist .single-column .section-title {
  flex: 1;
  text-align: right;
  padding-right: 20px;
}
.resume-container.minimalist .single-column .section > div:last-child { 
  flex: 4;
}
.resume-container.minimalist .header-section {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}
.resume-container.minimalist .details-section .section {
  margin: 0;
  padding: 0;
}
.resume-container.minimalist .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.minimalist .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.minimalist img.personal-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.resume-container.minimalist .header-section {
  padding: 0 !important;
  margin-bottom: 30px;
  border-bottom: 4px solid #333;
  justify-content: space-between;
}
.resume-container.minimalist .header-section .contact-detail .contact-value {
  display: flex;
  color: black;
  font-size: 13px;
}
.resume-container.minimalist .header-section .section.personal-details-section {
  margin-bottom: 7px;
}

.resume-container.minimalist .header-section .personal-details-section h1 {
  font-size: 34px;
  line-height: normal;
}

.resume-container.minimalist .languages-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}
.resume-container.minimalist .skills-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}

/* .resume-container.minimalist .single-column {
  padding: 30px 40px 0;
} */

.resume-container .single-column .section-title{
  line-height: normal;
}

/*expertise*/
.resume-container.expertise .single-column .section {
  display: flex;
}
.resume-container.expertise .single-column .section-title {
  flex: 1;
  text-align: right;
  padding-right: 20px;
}
.resume-container.expertise .single-column .section > div:last-child { 
  flex: 4;
}
.resume-container.expertise .header-section {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding: 30px 40px 20px;
  background: #d3f1ff;
  margin-bottom: 40px;
  justify-content: space-between;
}
.resume-container.expertise .details-section .section {
  margin: 0;
  padding: 0;
}
.resume-container.expertise .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.expertise .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.expertise .header-section .personal-photo {
  padding: 0;
  width: 100px;
  margin-right: 0;
}
.resume-container.expertise img.personal-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.resume-container.expertise .header-section .contact-detail .contact-value {
  display: flex;
  color: black;
  font-size: 13px;
}
.resume-container.expertise .header-section .section.personal-details-section {
  margin-bottom: 18px;
}

.resume-container.expertise .header-section .personal-details-section h1 {
  font-size: 34px;
  line-height: normal;
}

.resume-container.expertise .languages-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}
.resume-container.expertise .skills-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}

.resume-container.expertise {
  padding: 0;
}
.single-column .wrap {
  padding: 0 40px 0 30px;
}
/*Ambitious*/
.resume-container.ambitious .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.ambitious .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.ambitious::after {
    display: none;
}
.resume-container.ambitious .left-column {
  color: #000; 
}
.resume-container.ambitious .personal-details-section h1 {
  font-size: 28px;
  line-height: normal;
  color: #faa411;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}
.resume-container.ambitious h2.section-title {
  color: rgb(250, 164, 17);
  display: inline-flex;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 16px; 
  padding: 2px 0;
  border-bottom: 2px solid #cbcbcb;
}
/* .section.professional-summary-section .section-title {
  display: none !important;
} */
.resume-container.ambitious .header-section .personal-info {
  text-align: center;
}
.resume-container.ambitious .header-section {
  display: flex;
  background: #f3f3f3;
  padding: 25px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.resume-container.ambitious .columns {
  display: flex;
  padding: 30px !important;
  border-top: 0px solid #faa411;
}
.resume-container.ambitious .left-column,
.resume-container.ambitious .right-column{
  padding: 0px !important;
}
.resume-container.ambitious .personal-details-section {
  display: flex;
  margin-bottom: 0px;
  flex-direction: column;
}
/*Aspirations*/
.resume-container.aspirations .single-column .section {
  display: flex;
}
.resume-container.aspirations .single-column .section-title {
  flex: 1;
  text-align: right;
  padding-right: 20px;
}
.resume-container.aspirations .single-column .section > div:last-child { 
  flex: 4;
}
.resume-container.aspirations .header-section {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  background: #ffd3d3;
  margin-bottom: 40px;
  justify-content: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.resume-container.aspirations .header-section .details-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 !important;
  width: 100%;
}
.resume-container.aspirations .details-section .section {
  margin: 0;
  padding: 0;
}
.resume-container.aspirations .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.aspirations .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.aspirations .header-section .personal-photo {
  padding: 0;
  width: 100px;
  margin-right: 0;
}
.resume-container.aspirations img.personal-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.resume-container.aspirations .header-section .contact-detail .contact-value {
  display: flex;
  color: black;
  font-size: 13px;
}
.resume-container.aspirations .header-section .section.personal-details-section {
  margin-bottom: 0;
  padding: 28px 0;
}
.resume-container.aspirations .header-section .personal-info {
  text-align: center;
}
.resume-container.aspirations .header-section .personal-details-section h1 {
  font-size: 34px;
  line-height: normal;
}
.resume-container.aspirations .header-section .section.contact-details-section {
  width: 100%;
  float: left;
  background: #00000012;
}

.resume-container.aspirations .single-column .wrap {
  padding: 0 40px;
}
.resume-container.aspirations .header-section .section-content.contact-details-content {
  text-align: center;
  padding: 8px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; 
  float: left; 
  padding: 10px 20px;
}
.resume-container.aspirations .photo-section {
  display: none;
}
.resume-container.aspirations .languages-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}
.resume-container.aspirations .skills-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}

.resume-container.aspirations {
  padding: 0;
}

/*inspired*/
.resume-container.inspired .left-column {
    color: #333;
}
.resume-container.inspired .personal-details-section h1 {
  font-size: 34px;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 600;
}
.resume-container.inspired::after {
    background: #f0e4d4;
}
.resume-container.inspired .ratingForeColor {
    background: #000000bf !important;
}
.resume-container.inspired .ratingBackColor {
    background: #00000045 !important;
}
.resume-container.inspired h2.section-title {
  border-bottom: 2px solid #f0e4d4;
  padding-bottom: 5px;
}
.resume-container.inspired .sub-header {
  display: flex;
  justify-content: space-between;
}
.resume-container.inspired .header-section {
  display: flex;
  border-bottom: 8px solid #f0e4d4;
  margin-bottom: 30px;
  padding: 0 !important;
}
.resume-container.inspired .header-section .personal-details-section { 
  margin-bottom: 20px; 
}
.resume-container.inspired .section.professional-summary-section .section-title {
  display: none;
}

/*Proactive*/
.resume-container.proactive .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.proactive .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.proactive .header-section .personal-photo {
  padding: 0;
  width: 100px;
  margin-right: 25px;
  display: none;
}
.resume-container.proactive .single-column .wrap {
  padding: 0 40px;
}
.resume-container.proactive img.personal-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.resume-container.proactive .header-section {
  display: flex;
  padding: 20px 0 0 !important; 
  margin-bottom: 30px; 
  justify-content: center;
}
.resume-container.proactive .details-section {
  padding:20px 0 0 !important;
  width: 100%;
}
.resume-container.proactive .header-section .contact-detail .contact-value {
  display: flex;
  color: black;
  font-size: 13px;
}
.resume-container.proactive .header-section .section.personal-details-section {
  margin-bottom: 15px;
}

.resume-container.proactive .header-section .personal-details-section h1 {
  font-size: 34px;
  line-height: normal;
  text-transform: uppercase;
  font-weight: bolder;
  letter-spacing: 2px;
}
.resume-container.proactive .header-section .personal-details-section h2 {
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.resume-container.proactive .header-section .section-content.contact-details-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: 2px solid #efefef;
  border-bottom: 2px solid #efefef;
  padding: 14px !important;
  margin-bottom: 0;
}

.resume-container.proactive .languages-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}
.resume-container.proactive .skills-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}

.proactive.resume-container {
  padding: 0px;
}
.resume-container.proactive .single-column .section-title {
  line-height: normal;
  padding: 8px 15px;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 18px;
}
.resume-container.proactive .wrap .section {
  padding-bottom: 8px;
  border-bottom: 2px solid #efefef;
  margin-bottom: 10px;
}
.resume-container.proactive .section-content {
  padding: 0 15px;
  position: relative;
  margin-top: 0; 
  margin-bottom: 8px;
}
.resume-container.proactive .header-section .personal-info {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*Theme: Explorer*/
.resume-container.explorer {
  display: block; 
}
.resume-container.explorer:after{
  background: #f1f1f1;
}
.resume-container.explorer .header-section {
  display: flex;
  background: #283c50;
  z-index: 5;
  position: relative;
  color: #fff;
  margin: 0;
  top: 30px;
  border-right: 0;
  margin-bottom: 30px;
  border-top: 0;
  margin-right: 0;
  border-left: 0;
  padding: 25px 0;
}
.resume-container.explorer .left-column { 
  color: #000; 
}
.resume-container.explorer .details-section {
  padding: 0px; padding-left: 30px; }
.resume-container.explorer  .contact-details-section{
  float: left;
  font-size: 13px;
  color: #ffffffb3;
}
.resume-container.explorer .columns {
  display: flex;
  width: 100%; 
}
.resume-container.explorer .contact-details-section{
  margin: 0;
}
.resume-container.explorer .personal-details-section h1 {
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
}
.resume-container.explorer .sub-header {
  display: flex;
  justify-content: space-between;
}
.resume-container.explorer .sub-header .date-range {
  font-style: italic;
}
.resume-container.explorer .personal-details-section {
  margin-bottom: 10px;
}
.resume-container.explorer .header-section img.personal-photo {
  width: 100%;
  height: auto;
}
.resume-container.explorer .header-section .personal-info {
  text-align: left;
}
.resume-container.explorer .section.contact-details-section h2 {
  display: none;
}
.resume-container.explorer .contact-detail {
  float: left;
}

.resume-container.explorer .ratingForeColor{
  background: #283c50!important;
} 
.resume-container.explorer .ratingBackColor{
  background: #d3d3d3 !important;
}
.resume-container.explorer h2.section-title {
  padding-bottom: 4px;
}

/*Empowering*/
.resume-container.empowering .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.empowering .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.empowering .header-section .personal-photo {
  padding: 0;
  width: 100px; 
}
.resume-container.empowering .details-section .section-content {
  padding: 0 !important; 
}
.resume-container.empowering img.personal-photo {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.resume-container.empowering .header-section {
  display: flex;
  padding: 18px 0;
  background: #fff;
  margin: 34px;
  border-radius: 0;
  border: 4px solid #ffb8a2;
}
.resume-container.empowering .header-section .contact-detail .contact-value {
  display: flex;
  color: black;
  font-size: 13px;
}
.resume-container.empowering .header-section .section.personal-details-section {
  margin-bottom: 7px;
}

.resume-container.empowering .header-section .personal-details-section h1 {
  font-size: 34px;
  line-height: normal;
}
.resume-container.empowering .section.professional-summary-section .section-title {
  display: none;
}
.resume-container.empowering .languages-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}
.resume-container.empowering .skills-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
}

.empowering.resume-container {
  padding: 0px;
}
.resume-container.empowering .single-column .section-title {
  line-height: normal;
  background: #ffffff;
  padding: 8px 0;
  border-radius: 0;
  border-bottom: 2px solid #ffb8a2;
  color: #000000;
}
.resume-container.empowering .section-content {
  padding: 0 20px;
  position: relative;
  margin-top: 0;
  margin-left: 10px;
}
.resume-container.empowering .single-column .wrap {
  padding: 0 40px;
}
/*Theme: Professional*/
.resume-container.innovative {
  display: block; 
  color: #000; 
}
.resume-container.innovative:after{
  background: none;
}
.resume-container.innovative .header-section {
  display: flex;
  border-bottom: 1px solid #ccc;
  background: white;
  z-index: 5;
  position: relative; 
}
.resume-container.innovative .left-column { 
  color: #000; 
}
.resume-container.innovative .details-section {
  padding: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 15px;
}
.resume-container.innovative .columns {
  display: flex;
  width: 100%; 
  padding: 0;
}
.resume-container.innovative .left-column {
  color: #202020;
  border-right: 1px solid #cccccc;
}
.resume-container.innovative .personal-details-section {
  margin-bottom: 10px;
}
.resume-container.innovative .header-section .personal-photo {
  display: flex;
  padding: 15px;
  padding-right: 0px;
  height: 100%;
  align-items: center;
  width: 150px;
  display: none;
}
.resume-container.innovative .header-section img.personal-photo {
  width: 100%;
  height: auto;
}
.resume-container.innovative .header-section .personal-info {
  text-align: left;
}
.resume-container.innovative .section.contact-details-section h2 {
  display: none;
}
.resume-container.innovative .contact-detail {
  float: initial;
  text-align: right;
  margin-bottom: 5px;
}
.resume-container.innovative .header-section span.contact-icon {
  padding: 0;
  width: auto;
  margin-right: 5px;
}
.resume-container.innovative .ratingForeColor{
  background: #202020 !important;
} 
.resume-container.innovative .ratingBackColor{
  background: #d3d3d3 !important;
}
.resume-container.innovative .personal-details-section h1 {
  font-size: 45px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.resume-container.innovative .section-title {
  margin-bottom: 14px;
  font-size: 18px;
  font-weight: 600;
  border-bottom: 1px solid #ccc;
  text-transform: uppercase;
  padding-bottom: 7px;
}
.resume-container.innovative .section.contact-details-section {
  margin-bottom: 0;
  max-width: 236px;
}
/* Influential */

/*Theme: Versatile*/
.resume-container.versatile {
  display: block; 
  padding: 0;
}
.resume-container.versatile:after{
  background: none;
}
.resume-container.versatile .header-section {
  display: flex;
  background: #0b5595;
  z-index: 5;
  position: relative;
  color: #fff;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.resume-container.versatile .header-section .personal-photo {
  margin-right: 0 !important;
}
.resume-container.versatile .left-column { 
  color: #202020; 
}
.resume-container.versatile .details-section {
  padding: 30px 20px !important;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.resume-container.versatile .columns {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  padding:0 20px;
}
.resume-container.versatile .left-column {
  color: #202020;
  border-left: 1px solid #cccccc;
}
.resume-container.versatile .personal-details-section {
  margin-bottom: 10px;
}
.resume-container.versatile .header-section .personal-photo {
  display: flex; 
  padding-right: 0px;
  height: 100%;
  align-items: center;
}

.resume-container.versatile .header-section.photo-exists .personal-info {
  text-align: left !important;
}
.resume-container.versatile .personal-details-section h1 {
  font-size: 40px;
  font-weight: 700;
  line-height: normal;
}
.resume-container.versatile .header-section img.personal-photo {
  width: 100%;
  height: auto;
}
.resume-container.versatile .header-section .personal-info {
  text-align: center;
}

.resume-container.versatile .header-section span.contact-icon {
  padding: 5px 0 0 0;
  width: auto;
  margin-right: 5px;
}
.resume-container.versatile h2.section-title {
  display: inline-block;
  border-bottom: 2px solid #0b5595;
  padding-bottom: 5px;
}
.resume-container.versatile .ratingForeColor{
  background: #0b5595 !important;
} 
.resume-container.versatile .ratingBackColor{
  background: #d3d3d3 !important;
}
/*End visionary*/


/*dynamic*/
.resume-container.dynamic .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.dynamic .ratingBackColor{
  background: #cbcbcb !important;
}
.resume-container.dynamic .header-section .personal-photo {
  padding: 0;
  width: 95px !important;
  margin-right: 0; 
  border-radius: 5px;
  margin-right: 0 !important;
}
.resume-container.dynamic .header-section {
  margin-bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  padding: 30px 0 !important;
}
.resume-container.dynamic .details-section{
    padding: 0 !important;
    width: 84%;
}
.resume-container.dynamic .photo-section {
  width: 16%;
}
.resume-container.dynamic .single-column .section-content.skills-content.badge-content {
  display: flex;
}
.resume-container.dynamic .header-section .contact-detail .contact-value {
  display: flex;
  color: black;
  font-size: 14px;
}
.resume-container.dynamic .single-column .wrap {
  padding: 0;
}
.resume-container.dynamic .section-content.contact-details-content {
  float: left;
  width: auto; 
}

.resume-container.dynamic .header-section .section.personal-details-section {
  margin-bottom: 7px;
}

.resume-container.dynamic .header-section .personal-details-section h1 {
  font-size: 35px;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bolder !important;
  color: #cb9a08;
}
.resume-container.dynamic .header-section .personal-details-section h2 {
  font-size: 15px;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
}
.resume-container.dynamic .single-column .section-title{ 
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: #cb9a08;
  padding-bottom: 8px;
    border-bottom: 1px solid;
}
.resume-container.dynamic .single-column .sub-header {
  display: flex; 
}
.resume-container.dynamic .single-column .sub-header p.date-range, 
.resume-container.dynamic .single-column .sub-header .issue-date, 
.resume-container.dynamic .single-column .sub-header .end-date{
  font-style: italic;
  font-size: 13px;
  line-height: 25px;
}
.resume-container.dynamic .languages-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
  padding-bottom: 10px;
}
.resume-container.dynamic .skills-content {
  -moz-column-count: 4;
  -moz-column-gap: 20px;
  -webkit-column-count: 4;
  -webkit-column-gap: 20px;
  column-count: 2;
  column-gap: 85px;
  padding-bottom: 10px;
}

/*Theme: classic*/
.resume-container.classic {
  display: block; 
  padding: 0;
}
.resume-container.classic:after{
  background: none;
}
.resume-container.classic .header-section {
  display: flex;
  border-bottom: 1px solid #ccc; 
  z-index: 5;
  position: relative;
  align-items: center;
  padding: 0 26px !important;
}
.resume-container.classic .left-column { 
  color: #202020; 
}
.resume-container.classic .details-section {
  padding: 40px 10px !important;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
}
.resume-container.classic .columns {
  display: flex;
  width: 100%; 
  padding:0 20px;
}
.resume-container.classic .left-column {
  color: #202020;
  border-right: 1px solid #cccccc;
}
.resume-container.classic .personal-details-section {
  margin-bottom: 0px;
}
.resume-container.classic .section-content.skills-content, 
.resume-container.visionary .section-content.languages-content {
  padding-bottom: 10px;
}
.resume-container.classic .section-title {
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #000000;
  border-bottom: 1px solid #cccccc;
  position: relative;
  padding-bottom: 5px;
}
.resume-container.classic .section-title:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  background: black;
  left: 0;
  bottom: -1px;
}
.resume-container.classic .header-section .personal-photo {
  display: flex;
  z-index: 5;
  position: relative;
  color: #000000;
}
.resume-container.classic .header-section img.personal-photo{
  border-bottom: 1px solid #00000036;
  border-radius: 10px;
}
.resume-container.classic .personal-details-section h1 {
  font-size: 40px;
  font-weight: 600 !important;
  line-height: normal; 
  text-transform: uppercase;
}
.resume-container.classic .personal-details-section h2 {
  FONT-VARIANT: JIS04;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.resume-container.classic .header-section img.personal-photo {
  width: 100%;
  height: auto;
}
.resume-container.classic .header-section .personal-info {
text-align: center;
}
.resume-container.classic .header-section.photo-exists .personal-info {
  text-align: left;
}
.resume-container.classic .header-section span.contact-icon {
  padding: 5px 0 0 0;
  width: auto;
  margin-right: 5px;
}
.resume-container.classic .ratingForeColor{
  background: #000000 !important;
} 
.resume-container.classic .ratingBackColor{
  background: #d3d3d3 !important;
}
.resume-container.bold h2.section-title, 
.resume-container.explorer h2.section-title{
  text-transform: uppercase !important;
  padding-bottom: 8px;
}
.resume-container.creative h2.section-title {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 8px;
  margin-bottom: 10px;
}
.resume-container.influential h2.section-title{
  display: inline-block;
  border-bottom: 1px solid;
}
/*End classic*/
.resume-container h2.section-title {
  letter-spacing: 1px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: normal !important;
}
.resume-container h1{
  list-style: normal !important;
}
.header-section span.contact-text {
  line-height: 1.5 !important;
  word-break: break-word;
  width: inherit;
}

ul.badge-list {
  list-style: none !important;
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: flex-start;
}
ul.badge-list li {
  color: #fff;
  background: #292929;
  padding: 5px 7px !important;
  border-radius: 5px;
  line-height: 14px;
}
.resume-container .left-column span.contact-text{
  margin-right: 0;
}
.resume-container a.contact-value{
  text-decoration: none;
  color: inherit;
  pointer-events: none;
}
a.profile-url {
  color: inherit;
}
a.profile-url {
  line-height: 14px;
  word-break: break-all;
  display: inline-flex;
  font-style: italic;
  font-size: 14px;
  pointer-events: none;
}
.profile-link-entry {
  margin-bottom: 6px;
}
.profile-link-entry:last-child {
  margin: 0;
}
p.profile-type {
  font-weight: bold;
}
@media print {
  .resume-container a.contact-value, a.profile-url  {
    pointer-events: auto; 
  }
}
/*************************/

.resume-container.professional .header-section {
  background-color: var(--header-bg-color);
}
.resume-container.professional .ratingForeColor {
  background: var(--rating-fore-color) !important;
}
.resume-container.professional h2.section-title:after {
  background: var(--rating-fore-color) !important;
}
.resume-container.professional h2.section-title {
  border-bottom: 1px solid #cccccc;
  position: relative;
  padding-bottom: 8px;
}
.resume-container.professional h2.section-title:after {
  background: #000;
  position: absolute;
  content: '';
  width: 30px;
  height: 3px;
  bottom: -2px;
  left: 0;
}
.resume-container.professional ul.badge-list li{
  background: var(--rating-fore-color) !important;
}

.resume-container.creative h2.section-title,
.resume-container.dynamic .single-column .section-title,
.resume-container.classic .section-title,
.resume-container.elegant  h2.section-title
 {
  color: var(--section-title-color);
}
.resume-container.executive .header-section .personal-details-section h1,
.resume-container.creative .header-section .personal-details-section h1,
.resume-container.dynamic .header-section .personal-details-section h1,
.resume-container.classic .personal-details-section h1,
.resume-container.elegant .personal-details-section h1
 {
  color: var(--header-h1-color);
}
.resume-container.classic .ratingForeColor,
.resume-container.dynamic .ratingForeColor ,
.resume-container.creative .ratingForeColor,
.resume-container.executive .ratingForeColor {
  background: var(--header-h1-color) !important;
}
.resume-container.classic ul.badge-list li,
.resume-container.dynamic ul.badge-list li,
.resume-container.creative ul.badge-list li,
.resume-container.executive ul.badge-list li{
  background: var(--header-h1-color) !important;
}

.resume-container.classic span.contact-icon svg,
.resume-container.dynamic span.contact-icon svg,
.resume-container.creative span.contact-icon svg,
.resume-container.executive span.contact-icon svg  {
  fill: var(--header-h1-color);
}
.resume-container.executive .single-column .section-title{
  color: var(--header-h1-color);
}
.resume-container.executive .ratingForeColor,
.resume-container.creative .ratingForeColor {
  background: var(--rating-fore-color) !important;
}
.resume-container.executive ul.badge-list li,
.resume-container.creative ul.badge-list li{
  background: var(--rating-fore-color) !important;
}
.resume-container.visionary .header-section {
  background: var(--visionary-color) !important;
}
.resume-container.visionary .section-title{
  color: var(--visionary-color) !important;
}
.resume-container.visionary span.contact-icon svg
{fill: #b68510 !important;}
.resume-container.visionary ul.badge-list li{
  background: #b68510 !important;
}
.resume-container.modern .personal-details-section h1{
  color: var(--modern-title-color);
}
.resume-container.modern h2.section-title {
  color: var(--modern-section-title-color);
}
.resume-container.modern .left-column h2.section-title {
  color: #fff !important; 
  border-bottom: 3px solid #e5e5e538;
  padding-bottom: 5px;
}
.resume-container.modern:after {
  background: var(--modern-resume-after-bg);
}
.resume-container.modern:after {
  background: var(--modern-resume-after-bg);
}


.resume-container.impactful .right-column .section-title {
  border-bottom-color: var(--impactful-resume-after-bg);
  color:var(--impactful-resume-after-bg);
}
.resume-container.explorer .header-section{
  background: var(--explorer-resume-header-bg);
}
.resume-container.explorer .ratingForeColor{
  background: var(--explorer-resume-header-bg) !important;
}
.resume-container.explorer ul.badge-list li{
  background: var(--explorer-resume-header-bg) !important;
}
.resume-container.explorer .sub-section-col h3{
  color: var(--explorer-resume-header-bg) !important;
}

.resume-container.bold .header-section{
  background: var(--bold-resume-header-bg);
}
.resume-container.bold .ratingForeColor {
  background: var(--bold-rating-fore-color) !important;
}
.resume-container.bold ul.badge-list li{
  background: var(--bold-rating-fore-color) !important;
}
.resume-container.expertise .header-section {
  background: var(--expertise-resume-header-bg);
}
.resume-container.aspirations .header-section {
  background: var(--aspirations-resume-header-bg);
}
.resume-container.minimalist .header-section .personal-details-section h1,
.resume-container.minimalist .single-column .section-title{
  color: var(--minimalist-header-txt-color);
}

.resume-container.influential .header-section { 
  border-bottom-color: var(--influential-header-boder); 
}
.resume-container.inspired::after{
  background: var(--inspired-resume-after-bg);
}
.resume-container.inspired .header-section{
  border-bottom-color: var(--inspired-header-boder); 
}
.resume-container.versatile .header-section{
  background: var(--versatile-resume-header-bg);
}
.resume-container.versatile h2.section-title{
  border-bottom-color: var(--versatile-header-boder); 
}
.resume-container.versatile .ratingForeColor {
  background: var(--versatile-rating-fore-color) !important;
}
.resume-container.versatile ul.badge-list li{
  background: var(--versatile-rating-fore-color) !important;
}
.resume-container.versatile span.contact-icon svg {
  fill: var(--versatile-rating-fore-color);
}

.resume-container.vibrant .section-title {
  color: var(--vibrant-title-color);
  border-bottom-color: var(--vibrant-border-color);
}
.resume-container.empowering .header-section{
  border-color: var(--empowering-border-color);
  background: var(--empowering-border-color);
}
.resume-container.empowering .single-column .section-title{
  border-bottom-color: var(--empowering-border-bottom-color);
}

.resume-container.innovative .personal-details-section h1{
  color:var(--innovative-title-color);
}
.resume-container.innovative span.contact-icon svg {
  fill: var(--innovative-contact-fill);
}
.resume-container.innovative .ratingForeColor{
  background: var(--innovative-rating-bg-color) !important;
}
.resume-container.innovative ul.badge-list li{
  background: var(--innovative-rating-bg-color) !important;
}

.resume-container.ambitious .personal-details-section h1,
.resume-container.ambitious h2.section-title {
  color:var(--ambitious-title-color);
}
.resume-container.ambitious .left-column,
.resume-container.ambitious .columns {
  border-color: var(--ambitious-boder-color);
}
.resume-container.ambitious .ratingForeColor {
  background: var(--ambitious-boder-color) !important;
}
.resume-container.ambitious ul.badge-list li{
  background: var(--ambitious-boder-color) !important;
}
.resume-container.ambitious span.contact-icon svg{
  fill: var(--ambitious-boder-color) !important;
}
.resume-container.proactive .ratingForeColor {
  background: var(--proactive-color) !important;
}
.resume-container.proactive ul.badge-list li{
  background: var(--proactive-color) !important;
}
.resume-container.proactive span.contact-icon svg{
  fill: var(--proactive-color) !important;
}


/*modran, impactful, influential, vibrant */
.resume-container.modern .left-column{
  background: var(--modern-color);
}
.resume-container.modern .right-column .ratingForeColor{
  background: var(--modern-color) !important;
} 
.resume-container.modern .right-column ul.badge-list li{
  background: var(--modern-color) !important;
}
.resume-container.modern ul.badge-list li{
  background: #ffffff26 !important;
}
.resume-container.modern .right-column .ratingBackColor{
  background: #acacaca8 !important;
}
.resume-container.modern .right-column span.contact-icon svg{
  fill: var(--modern-color) !important;
}

.resume-container.impactful .left-column{
  background: var(--impactful-color);
}
.resume-container.impactful .right-column .ratingForeColor{
  background: var(--impactful-color) !important;
} 
.resume-container.impactful ul.badge-list li{
  color: #000 !important;
  background: #fff !important;
}
.resume-container.impactful .right-column ul.badge-list li{
  color: #ffffff !important;
  background: var(--impactful-color) !important;
}
.resume-container.impactful .right-column .ratingBackColor{
  background: #acacaca8 !important;
}
.resume-container.impactful .right-column span.contact-icon svg{
  fill: var(--impactful-color) !important;
}

.resume-container.influential .left-column{
  background: var(--influential-color);
}
.resume-container.influential .right-column .ratingForeColor{
  background: var(--influential-color) !important;
} 
.resume-container.influential ul.badge-list li {
  background: #ffffff26 !important;
  color: white;
}
.resume-container.influential .right-column ul.badge-list li {
  background: var(--influential-color) !important;
  color: white;
}
.resume-container.influential .right-column .ratingBackColor{
  background: #acacaca8 !important;
}
.resume-container.influential .right-column span.contact-icon svg{
  fill: var(--influential-color) !important;
}

.resume-container.vibrant .left-column{
  background: var(--vibrant-color);
}
.resume-container.vibrant .right-column .ratingForeColor{
  background: var(--vibrant-color) !important;
} 
.resume-container.vibrant ul.badge-list li {
  background: #ffffff24 !important;
}
.resume-container.vibrant .right-column ul.badge-list li {
  background: var(--vibrant-color) !important;
}
.resume-container.vibrant .right-column .ratingBackColor{
  background: #acacaca8 !important;
}
.resume-container.vibrant .right-column span.contact-icon svg{
  fill: var(--vibrant-color) !important;
}

.resume-container.inspired .left-column {
  background: var(--inspired-color); 
}
.resume-container.bold .left-column {
  color: #fff;
  background: #26262d;
}
.resume-container.explorer .left-column {
  background: #f1f1f1;
}
/* .resume-container.explorer::after {
  background: #f1f1f1;
  width: 33.5333333%;
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
  content: '';
}
.resume-container.bold::after {
  background: #26262d;
  width: 33.5333333%;
  left: 0;
  top: 0;
  height: 100%;
  position: absolute;
  content: '';
} */

/*Section space controll*/
.resume-container .section {
  margin-bottom: var(--section-margin); 
}
.header-section .section {
  margin-bottom: 0;
}

.section ul {
  list-style: disc;
  width: 100%;
  padding-left: 25px;
  margin-left: 0;
}
/* .section-content {
  min-height: 30px;
} */
.resume-container .header-section .section-content {
  min-height: initial;
}
.resume-container:before {
  z-index: 9;
  right: 0;
  content: "";
  border-bottom: 1px dashed rgb(12, 25, 37);
  top: 11in;
  position: absolute;
  width: 100%;
  height: 1px;
}
.resume-container.bold:before,
.resume-container.explorer:before {
  content: "";
  border-bottom: none;
  position: absolute;
  background: #26262d;
  top: 0;
  z-index: 1;
  width: 268px;
  height: 230px;
  left: 0;
}
.resume-container.explorer:before{
  background: #f1f1f1;
}
/* Print Styles */
@media print {
  body, body:after, .resume-container{
      background: none;
      width: 100%;
      margin:0;
      padding:0;
  }
  .section-content {
    min-height: initial;
  }
  .resume-container:before {
    display: none;
    }
  .resume-container {   
    width: 100%;
    max-width: inherit;
    box-shadow: none;
    transform: scale(1) !important;
    min-width: initial;  
    width: 800px;
  }
 
  .resume-container.modern .left-column,
  .resume-container.impactful .left-column,
  .resume-container.influential .left-column,
  .resume-container.vibrant .left-column{
    background: none;
  }
  .resume-container.bold:before ,
  .resume-container.explorer:before,
  .resume-container.explorer::after,
  .resume-container.explorer::after{
    background: none;
  }
  .resume-container.bold .left-column,
  .resume-container.explorer .left-column {
    background: none;
  }
  .section ul {
    list-style: disc;
    width: calc(100% - 25px);
    padding-left: 25px;
    margin-left: 0;
  }
}
.resume-container.inspired .details-section {
  padding: 0 !important;
}

.resume-container.minimalist .header-section .personal-photo {
  margin-right: 0px !important;
  margin-top: 5px;
}
.resume-container.expertise .header-section .personal-photo {
  padding: 0;
  margin-top: 20px;
  margin-right: 0 !important;
}
.resume-container .header-section{
  padding: 0 45px !important;
}
.resume-container .details-section {
  padding: 25px 0 !important;
}
.resume-container .header-section .personal-photo {
  display: flex; 
  margin: 0px;
  height: 100%;
  align-items: center;
  margin-right: 25px !important; 
}
.resume-container .header-section .personal-photo-img {
  border-radius: 50%;
  width: 105px !important;
  border: 1px solid #c9c9c9;
}
.resume-container .left-column .personal-photo-img {
  border-radius: 50%;
  width: 110px !important;
  border: 1px solid #c9c9c9;
}
.resume-container.dynamic .header-section .personal-photo-img{
  border-radius: 10px;
  border: 1px solid #dbdbdb;
  width: 105px;
}
/*******************/
/* .executive.resume-container{ 
  padding:20px 50px !important;
} */

/**********/
.executive.resume-container{
  /* width: calc(100% - 100px); */
  /* padding:0px 50px !important; */
}
.resume-container.elegant,
.expertise.resume-container,
.aspirations.resume-container,
.proactive.resume-container,
.empowering.resume-container{
  padding: 0px;
}
.resume-container.vibrant .header-section {
  margin-bottom: 30px;
    padding: 0 !important;
}
.aspirations.resume-container .section.professional-summary-section .section-title {
  display: block !important;
}
.resume-container.visionary .columns {
  display: flex;   
  padding: 0 20px;
}
.columns {
  display: flex;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 auto;
}
.resume-container.innovative .header-section .section-content.contact-details-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.resume-container.influential .details-section {
  padding: 0 0 30px  !important;
}
.resume-container.influential .header-section{
  padding: 0 !important;
}
.resume-container.elegant .details-section {
  padding: 0 0 30px !important;
}
.resume-container.vibrant .details-section {
  padding: 0 !important;
}
.resume-container.impactful .details-section {
  padding: 0 0 10px !important;
}
/* .column-wrap{
  padding: 0 15px;
} */
.column-wrap{
  display:flex ;
  width: 100%;
}
.ambitious .column-wrap,
.impactful .column-wrap,
.explorer .column-wrap,
.bold .column-wrap,
.influential .column-wrap{
  padding: 0;
  width: 100%;
}
.ambitious .column-wrap .columns{
  display: flex;
  flex-direction: row-reverse;
}
.resume-container.ambitious .columns {
  gap: 30px; 
  width: auto !important;
}
.resume-container span.contact-icon svg {
  font-size: 13px;
  top: 1px; 
  position: relative;
  margin-right: 8px;
}
.header-section span.contact-icon {
  padding: 0;
  width: auto;
  margin-right: 5px;
  position: relative;
  top:0;
}
.resume-container .header-section span.contact-icon svg {
  font-size: 13px;
  top: 1px;
  position: relative;
  margin-right: 0;
}
.header-section .contact-detail .contact-value {
  display: flex; 
  align-items: baseline;
}
.innovative .header-section .contact-detail .contact-value {
  display: flex;  
  flex-direction: row-reverse;
}
.innovative .header-section .contact-detail span.contact-text { 
  margin-right: 6px;
  line-height: 1.2 !important;
}
.left-column .section ul {
  padding-left: 16px;
}
.single-column {
  width: 100%;
  margin:15px 40px;
}
/* .resume-container.aspirations {
  padding: 10px 40px;
}
.resume-container.dynamic {
  padding: 10px 50px;
} */
/* .skill-set:not(:last-child), 
.languages-set:not(:last-child) {
    margin-bottom: 10px;
} */
.resume-container.inspired .left-column .personal-photo img {
  border: 1px solid #3b3834;
}

.header-section.photo-exists {
  display: flex;
  justify-content: space-between;
}

.header-section.photo-exists .details-section {
  display: flex;
  justify-content: flex-start;
  width: inherit;
}

/* .header-section.photo-exists .section-content.personal-info {
  text-align: left;
} */
.header-section img.personal-photo { 
  border: 2px solid #ffffff70;
}

.resume-container.content-left .section-content {
  text-align: left;
}
.resume-container.content-right .section-content {
  text-align: right;
}
.resume-container.content-center .section-content {
  text-align: center;
}
.resume-container.content-justify .section-content {
  text-align: justify;
}

 /* Default single-column layout for skills */
 .skills-section .skills-content,
 .languages-section .languages-content {
   display: flex;
   flex-wrap: wrap;
 }
 .left-column .skills-section .skills-content,
 .left-column .languages-section .languages-content  {
   flex-wrap: nowrap;
   flex-direction: column;
 } 
 .right-column .skills-section .skills-content,
 .single-column .skills-section .skills-content,
 .right-column .languages-section .languages-content,
 .single-column .languages-section .languages-content{
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   row-gap: 5px; 
   column-gap: 30px;
 }
 .resume-container.dynamic .single-column .skills-section .skills-content{
  column-gap: 50px;
 }
 .right-column .skills-section .skills-content.badge-content,
 .single-column .skills-section .skills-content.badge-content  {
  display: flex;
  flex-wrap: wrap;
}
 .left-column .sub-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.resume-container.innovative .details-section {
  padding: 30px 0 10px !important;
}
.resume-container.versatile .header-section .personal-photo-img {
  width: 100px !important;
}
.resume-container.explorer .header-section .personal-photo,
.resume-container.explorer .header-section .personal-photo-img,
.resume-container.versatile .header-section .personal-photo,
.resume-container.versatile .header-section .personal-photo-img,
.resume-container.professional .header-section .personal-photo,
.resume-container.professional .header-section .personal-photo-img{
  margin:0;
  padding:0;
}
.resume-container.aspirations .single-column,
.expertise.resume-container .single-column,
.proactive.resume-container .single-column,
.empowering.resume-container .single-column{
  padding: 0;
  margin: 0;
}

.handle {
  cursor: move; /* or use grab */
  background-color: #ccc; /* just an example */
  text-align: center;
  font-size: 20px;
}
.hide-before-element::before{
  border: none !important;
}

 